import { CommonModule, registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import enExtra from "@angular/common/locales/extra/en";
import nlExtra from "@angular/common/locales/extra/nl";
import nl from "@angular/common/locales/nl";
import { Component, OnInit, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, filter, firstValueFrom, merge } from "rxjs";
import { ENVIRONMENT } from "src/environments/environment";
import { ORGANISATION } from "src/environments/organisation";
import { ApplicationService } from "src/services/application.service";
import { SessionService } from "src/services/session.service";
import { DefaultComponent } from "./default.component";
import { SplashScreenComponent } from "./splash/splash.component";
@Component({
  standalone: true,
  selector: "app-root",
  imports: [RouterOutlet, SplashScreenComponent, CommonModule],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less", "./app.print.less"],
})
export class AppComponent extends DefaultComponent implements OnInit {
  public application: ApplicationService;
  public session: SessionService;
  private translation: TranslateService;
  public title: Title;

  public initialized: boolean;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.session = inject(SessionService);
    this.translation = inject(TranslateService);
    this.title = inject(Title);

    this.initialized = false;

    registerLocaleData(nl, "nl", nlExtra);
    registerLocaleData(en, "en", enExtra);

    this.application.locale
      .pipe(
        distinctUntilChanged(),
        filter((value) => this.translation.currentLang !== value),
      )
      .subscribe((locale) => {
        this.translation.use(locale);
      });

    merge(this.session.workspace, this.session.role).subscribe(() => this.setTitle());

    if (ENVIRONMENT.DEBUG) {
      console.warn(`[APPLICATION] Configuration => `, {
        organisation: ORGANISATION.NAME,
        production: !ENVIRONMENT.DEBUG,
        api: ORGANISATION.API,
        status: ENVIRONMENT.STATUS,
      });
    }
  }

  public async ngOnInit(): Promise<void> {
    try {
      await this.session.initialize();
      setTimeout(() => {
        this.initialized = true;
      }, 500);
    } catch (err) {
      console.error("Fatal error!", err);
    }
  }

  private async setTitle(): Promise<void> {
    const workspace = await firstValueFrom(this.session.workspace);
    const role = await firstValueFrom(this.session.role);

    if (role && workspace) {
      this.title.setTitle(`${workspace.displayname} - ${role.displayname} | ${this.application.name}`);
    } else {
      this.title.setTitle(`${this.application.name}`);
    }
  }
}
