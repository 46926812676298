import { Injectable, inject } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { ORGANISATION } from "src/environments/organisation";
import { ROUTES_CONFIG } from "../config/routes.config";
import { HttpService } from "./http.service";

export enum SOCKET_IN {
  COMMUNICATION = "communication",
  SESSION = "session",
}

export enum SOCKET_OUT {
  AUTHENTICATE = "AuthenticateAsync",
}

export interface SocketEvent {
  eventname: string;
  data: unknown;
  idfWorkspaceID: string;
}

export interface SocketConnection {
  id: number;
  username: string;
  status: string;
  connections: Map<string, Record<string, unknown>>;
  mobile: boolean;
}

export interface UserResponse {
  idgSchaalIDConferenceStatus: string | number;
  omlRole: string;
  omlUsername: string;
  omlWorkspace: string;
  omsConferenceStatus: string;
  socConnectionid: string;
  idfGebruikerID: number;
  janAllMobile: boolean;
}

export interface RoomResponse {
  janAfgesloten: boolean;
  omlGespreksruimte: string;
  idfAdministratieID: number;
  janVerbonden: boolean;
  omsDatum_Gemaakt: string;
  omlRoomID: string;
  omsNaamDeelnemer: string;
  idfGebruikerIDDeelnemer: number;
}

export enum SOCKET_STATE {
  CONNECTED,
  RECONNECTING,
  CLOSED,
}

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  public http: HttpService;
  public state: BehaviorSubject<SOCKET_STATE>;
  public connections: BehaviorSubject<Map<number, SocketConnection>>;

  private socket: HubConnection | null;

  public constructor() {
    this.http = inject(HttpService);

    this.state = new BehaviorSubject<SOCKET_STATE>(SOCKET_STATE.CLOSED);
    this.connections = new BehaviorSubject(new Map());

    if (ORGANISATION.WEBSOCKET) {
      this.socket = new HubConnectionBuilder().withUrl(ORGANISATION.WEBSOCKET).build();
      this.socket.onclose((e) => this.onClose(e));
      this.socket.onreconnecting((e) => this.onReconnect(e));
      this.socket.onreconnected((e) => this.onReconnected(e));
    } else {
      this.socket = null;
    }
  }

  public getSocket(): HubConnection {
    if (this.socket) {
      return this.socket;
    } else {
      throw new Error("Unable to get socket.");
    }
  }

  /**
   * Connect the socket
   */
  public async connect(): Promise<void> {
    try {
      await this.getSocket().start();
      this.state.next(SOCKET_STATE.CONNECTED);
    } catch {
      console.error("[SOCKET] Unable to create connection");
      this.state.next(SOCKET_STATE.CLOSED);
    }
  }

  /**
   * Close the socket connection
   */
  public disconnect(): void {
    try {
      this.getSocket().stop();
      this.state.next(SOCKET_STATE.CLOSED);
    } catch {
      console.error("[SOCKET] Unable to close connection");
    }
  }

  public getConnectionId(): string {
    const id = this.getSocket().connectionId;

    if (id) {
      return id;
    } else {
      throw new Error("Could not find socket connection id.");
    }
  }

  /**
   * Authenticate the clients
   */
  public async authenticate(id: string, index: string): Promise<void> {
    try {
      const socketid = await this.get(SOCKET_OUT.AUTHENTICATE, id, index);

      this.http
        .retrieve<UserResponse[]>(ROUTES_CONFIG.conferenceUsers, {})
        .then((value) => {
          this.updateConnections(...value);
        })
        .catch((error) => {
          console.warn("Unable to retrieve connections => ", error);
        });

      console.warn("[SOCKET] Successfully authenticated => ", socketid);
    } catch (error) {
      console.error("[SOCKET] Unable to authenticate", error);
    }
  }

  /**
   * Update current active connections
   * @param connections
   */
  public async updateConnections(...connection: UserResponse[]): Promise<void> {
    const connections = await firstValueFrom(this.connections);

    for (const con of connection) {
      let unique = connections.get(con.idfGebruikerID);
      let { idgSchaalIDConferenceStatus: status } = con;
      status = typeof status == "string" ? status : status.toString();

      if (!unique) {
        unique = {
          id: con.idfGebruikerID,
          username: con.omlUsername,
          status,
          connections: new Map(),
          mobile: con.janAllMobile || false,
        };
      }
      unique.status = status;
      if (con.socConnectionid) unique.connections.set(con.socConnectionid, {});
      connections.set(con.idfGebruikerID, unique);
    }

    this.connections.next(connections);
  }

  public getConnectionById(connectionid: string): { id: number; connection: SocketConnection } | null {
    for (const [id, connection] of this.connections.value.entries()) {
      const data = connection.connections.get(connectionid);
      if (data) return { id, connection };
    }
    return null;
  }

  /**
   * Shorthand on method
   * @param event
   * @param callback
   */
  public listen<EVENT = SOCKET_IN>(event: EVENT, callback: (params: SocketEvent) => void): void {
    try {
      this.getSocket().on(<string>event, callback);
    } catch (error) {
      console.error("[SOCKET] Unable to listen");
    }
  }

  /**
   * Shorthand send method
   * @param event
   * @param args
   */
  public send<EVENT = SOCKET_OUT>(event: EVENT, id: string, ...args: unknown[]): void {
    try {
      this.getSocket().send(<string>event, id, ...args);
    } catch (error) {
      console.error("[SOCKET] Unable to send");
    }
  }

  /**
   * Shorthand invoke method
   * @param event
   * @param args
   * @returns
   */
  public async get<T = unknown, EVENT = SOCKET_OUT>(event: EVENT, id: string, ...args: unknown[]): Promise<T> {
    return this.getSocket().invoke<T>(<string>event, id, ...args);
  }

  /**
   * Fired when socket lost connection with server
   * @param error
   */
  private onClose(error: Error | undefined): void {
    console.warn(`[SOCKET] Lost connection with server..`, { error });
    this.state.next(SOCKET_STATE.CLOSED);
  }

  /**
   * Fired when trying to reconnect to server
   * @param error
   */
  private onReconnect(error: Error | undefined): void {
    console.warn(`[SOCKET] Trying to reconnect to server..`, { error });
    this.state.next(SOCKET_STATE.CLOSED);
  }

  /**
   * Fired when connection is reestablished
   * @param connectionid
   */
  private onReconnected(connectionid: string | undefined): void {
    console.warn(`[SOCKET] Successful reconnect to server.`, { connectionid });
    this.state.next(SOCKET_STATE.CONNECTED);
  }
}
