import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ActivatedRoute, Params, Router, RouterModule } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, distinctUntilChanged, firstValueFrom } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { STORAGE } from "src/config/storage.config";
import { TooltipDirective } from "src/directives/tooltip.directive";
import { LOCALES } from "src/enums/locales";
import { ENVIRONMENT } from "src/environments/environment";
import { ORGANISATION } from "src/environments/organisation";
import { SessionLoginJWT } from "src/interfaces/session/session-login-jwt";
import { SessionLoginPermalink } from "src/interfaces/session/session-login-permalink";
import { SessionLoginQR } from "src/interfaces/session/session-login-qr";
import { SessionLoginTraditional } from "src/interfaces/session/session-login-traditional";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { PrefixOmsComponent } from "../../global/prefixes/impl/prefix-oms/prefix-oms.component";
import { PrefixQrcComponent } from "../../global/prefixes/impl/prefix-qrc/prefix-qrc.component";
import { PrefixWacComponent } from "../../global/prefixes/impl/prefix-wac/prefix-wac.component";
import { TemplateTextComponent } from "../../global/prefixes/templates/template-text/template-text.component";
import { ErrorMessageComponent } from "../../global/snackbar/impl/error-message/error-message.component";
import { SnackbarService } from "../../global/snackbar/snackbar.service";
import { TimerComponent } from "../../global/timer/timer.component";
import { QRCodeResponse } from "./qrcode.interface";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [
    PrefixOmsComponent,
    PrefixWacComponent,
    TranslateModule,
    RouterModule,
    PrefixQrcComponent,
    TimerComponent,
    CommonModule,
    MatIconModule,
    TooltipDirective,
    FormsModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.less",
})
export class LoginComponent extends DefaultComponent implements AfterViewInit, OnInit {
  public application: ApplicationService;
  private session: SessionService;
  private router: Router;
  private route: ActivatedRoute;
  private http: HttpService;
  private snackbar: SnackbarService;
  private msal: MsalService;

  public isLoading: boolean;
  public showQR: BehaviorSubject<boolean>;
  public qrcode: string | null;
  public qrIntervalTime: number;
  public checkInterval: NodeJS.Timeout | undefined;
  public news: string | null;

  @Input()
  public showNews: boolean;

  public env: typeof ORGANISATION;

  @ViewChild("usernameRef")
  public usernameRef: ElementRef<HTMLDivElement> | null;

  @ViewChild("passwordRef")
  public passwordRef: ElementRef<HTMLDivElement> | null;

  @ViewChild("username")
  public username: TemplateTextComponent | null;

  @ViewChild("password")
  public password: TemplateTextComponent | null;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.session = inject(SessionService);
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
    this.http = inject(HttpService);
    this.snackbar = inject(SnackbarService);
    this.msal = inject(MsalService);

    this.isLoading = false;
    this.showQR = new BehaviorSubject(false);
    this.qrcode = null;
    this.qrIntervalTime = ORGANISATION.QR_REFRESH_RATE;
    this.checkInterval = undefined;

    this.env = ORGANISATION;

    this.usernameRef = null;
    this.passwordRef = null;
    this.username = null;
    this.password = null;

    this.showNews = true;
    this.news = null;
  }

  public async ngOnInit(): Promise<void> {
    this.addSubscription(
      this.showQR.pipe(distinctUntilChanged()).subscribe((value) => {
        if (value) this.generateQR();
        this.toggleCheck(value);
      }),
      this.route.params.subscribe(async (params: Params) => {
        const id = params["id"];
        if (id) {
          switch (id) {
            case "sso":
              this.handleSSO();
              break;

            default:
              await this.handleLogin({
                ffwdrequest: id,
              });
          }
        }
      }),
    );

    if (this.showNews) {
      this.news =
        (
          await this.http.retrieve<[{ getDataAsHTML: string; getDataAsJSON: string }]>(ROUTES_CONFIG.unsecureurl, {
            datatypeid: "maintenancemessage",
          })
        ).shift()?.getDataAsHTML || null;
    }
  }

  public ngAfterViewInit(): void {
    this.usernameRef?.nativeElement.querySelector("input")?.focus();
  }

  /**
   * Set locale of user
   * @param language
   */
  public setLocale(language: "EN" | "NL"): void {
    this.application.locale.next(LOCALES[language]);
  }

  /**
   * Handles session login
   */
  public async login(): Promise<void> {
    const usernameField = this.username;
    const passwordField = this.password;

    if (usernameField && passwordField) {
      const username = usernameField.value;
      const password = passwordField.value;

      this.isLoading = true;

      try {
        if (username && password) {
          await this.handleLogin({
            cdjID: username,
            wacPassword: password,
            RoleID: localStorage.getItem(STORAGE.ROLE_ID),
            WorkspaceID: localStorage.getItem(STORAGE.WORKSPACE_ID),
          });
        } else {
          throw new Error("Invalid form");
        }
      } catch (err) {
        console.error(err);
      }

      this.isLoading = false;
    } else {
      throw new Error("Could not find username or password References");
    }
  }

  /**
   * Toggle qr-code view
   */
  public toggleQR(): void {
    this.showQR.next(!this.showQR.value);
  }

  /**
   * Generate a new qr code
   */
  public async generateQR(): Promise<void> {
    try {
      const response = await this.http.retrieve<QRCodeResponse[]>(ROUTES_CONFIG.unsecureurl, {
        datatypeid: "getloginQR",
      });
      const qrcode = response[0].getDataAsHTML;

      if (qrcode) {
        this.qrcode = qrcode;
      } else {
        throw new Error("Invalid QRCode generated.");
      }
    } catch (e) {
      this.snackbar.open<string>(ErrorMessageComponent, "ERRORS.INVALID_QR");
      this.showQR.next(false);
      this.qrcode = null;
    }
  }

  /**
   * Enable/disable interval to check qr-code login
   * @param activate
   */
  private toggleCheck(activate: boolean): void {
    if (activate) {
      this.checkInterval = setInterval(() => {
        this.checkQR().catch((e) => {
          if (ENVIRONMENT.DEBUG) console.warn(e);
        });
      }, ORGANISATION.QR_CHECK_RATE);
    } else {
      clearInterval(this.checkInterval);
      this.checkInterval = undefined;
    }
  }

  /**
   * Check if qrcode has been scanned and then login
   */
  private async checkQR(): Promise<void> {
    const response = await this.http.retrieve<QRCodeResponse[]>(ROUTES_CONFIG.unsecureurl, {
      datatypeid: "checkloginQR",
      qr: this.qrcode,
    });

    const request = response[0].getDataAsHTML;

    if (request) {
      await this.session.connect({
        ffwdrequest: request,
      });
      if ((await firstValueFrom(this.session.authenticated)) == true) {
        this.toggleCheck(false);
        await this.router.navigate(["app/home"]);
      }
    } else {
      throw new Error("Undefined ffwdrequest in login");
    }
  }

  /**
   * Handle post login request
   * @param data
   */
  private async handleLogin(data: SessionLoginTraditional | SessionLoginQR | SessionLoginPermalink | SessionLoginJWT, url?: string): Promise<void> {
    await this.session.connect(data, url);
    if ((await firstValueFrom(this.session.authenticated)) == true) {
      this.toggleCheck(false);
      const contentid = this.route.snapshot.queryParams["contentid"];
      await this.router.navigate(contentid ? ["/app/content", contentid] : ["/app/home"]);
    }
  }

  private handleSSO(): void {
    if (!ORGANISATION.MSAL) {
      console.error("SSO is not enabled for your organisation.");
      this.router.navigate(["/login"]);
      return;
    }
    this.isLoading = true;

    this.addSubscription(
      this.msal.handleRedirectObservable().subscribe(async (result) => {
        if (!result) {
          const account = this.msal.instance.getActiveAccount() ?? this.msal.instance.getAllAccounts()[0] ?? undefined;
          this.msal.acquireTokenRedirect({ scopes: [], account });
        } else {
          try {
            await this.handleLogin({ jwt: result.idToken }, ROUTES_CONFIG.loginJwtUrl);
          } finally {
            this.isLoading = false;
          }
        }
      }),
    );
  }
}
