import { EnvironmentHelper } from "src/classes/EnvironmentHelper";
import { OrganisationConfig } from "src/interfaces/environment";

const CONFIG: OrganisationConfig = {
  NAME: "epf",
  TITLE: "Energie Platform",
  API: null,
  WEBSOCKET: null,
  SHOW_HISTORY: true,
  QR_LOGIN: true,
  QR_REFRESH_RATE: 60000,
  QR_CHECK_RATE: 5000,
  MSAL: null,
  SUBFUNCTIONALITIES: [],
  MAP: null,
};

const CONFIG_DEVELOPMENT: Partial<OrganisationConfig> = {
  TITLE: "Energie Platform | Development",
  API: "https://dev.api.epf.bespaartenergie.net",
  WEBSOCKET: "https://dev.api.epf.bespaartenergie.net/socket",
};

const CONFIG_STAGING: Partial<OrganisationConfig> = {};

const CONFIG_PRODUCTION: Partial<OrganisationConfig> = {};

export const ORGANISATION = EnvironmentHelper.GetEnvironment({
  CONFIG,
  CONFIG_DEVELOPMENT,
  CONFIG_STAGING,
  CONFIG_PRODUCTION,
});
